class MdcImageList extends HTMLElement {

  constructor() {
    super();
  }

  connectedCallback() {
  }

  disconnectedCallback() {
  }
};

customElements.define("mdc-image-list", MdcImageList);
